import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const DropdownBox = styled.div<{
  $visible: boolean;
  $lastElement: boolean;
  $datePicker: boolean;
}>(
  ({ theme, $visible, $lastElement, $datePicker }) => `
  display: ${$visible ? 'block' : 'none'};
  position: fixed;
  z-index: 1;
  background-color: ${theme.colors.background};
  border-radius: calc(${theme.spacing[1]} - 4px);
  border: 1px solid rgba(18, 59, 43, 0.20);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 2px 2px 0px rgba(30, 30, 30, 0.16);
  left: 50%;
  transform: translateX(-50%);

  ${breakpoints.desktopOrBigger} {
    left: auto;
    transform: ${$lastElement ? 'translateX(-63%)' : 'translateX(-0%)'};
  }

  ${breakpoints.mobile} {
    width:${$datePicker ? '' : `calc(100% - ${theme.spacing[2]})`};
    margin-top:${theme.spacing[2]};
    transform: translateX(-50%);
  }
`,
);

export const ContainerWrapper = styled.div(
  ({ theme }) => `
  .arrowIconUp {
    transition: all 200ms linear;
    transform: rotate(-180deg);
  }
  .arrowIconDown {
    transition: all 200ms linear;
    transform: rotate(0deg);
  }
`,
);

export const ButtonContainer = styled.div<{ $active: boolean; $mobile: boolean }>(
  ({ theme, $active, $mobile }) => `
  display: flex;
  width:max-content;
  justify-content: center; 
  align-items: center; 
  color: ${theme.colors.text};
  padding: ${$mobile ? '4px' : '4px 8px'};
  border: none;
  cursor: pointer;
  text-align: left;
  font-size:${theme.typography3.fontSize};
  font-weight:${theme.typography3.fontWeight};
  line-height:${theme.typography3.lineHeight};
  border-radius: calc(${theme.spacing[1]} - 4px);
  border: 1px solid ${$active ? 'rgba(18, 59, 43, 0.10)' : 'transparent'}; 
  background: ${$active ? theme.colors.actionHover : 'none'};
  
  ${breakpoints.mobile} {
    scroll-snap-aing: start;    
  } 
`,
);

export const ButtonLabel = styled.span<{ $mobile: boolean }>(
  ({ theme, $mobile }) => `
  margin-left: ${theme.spacing[1]};
  margin-right: ${$mobile ? '4px' : '14px'};
`,
);

export const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  &:checked .buttonContainer {
    border-radius: 4px;
    background: rgba(106, 199, 136, 0.20);
  }

  &:checked .arrowIconDown {
    transform: rotate(-180deg);
    transition: all 200ms linear;
  }
}
`;

export const SupportContentWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding-inline: ${theme.spacing[2]};
  padding-top:${theme.spacing[2]};
  margin-bottom: ${theme.spacing[1]};
`,
);

export const DropdownContent = styled.div<{ $datePicker: boolean }>(
  ({ theme, $datePicker }) => `
  display: flex;
  flex-direction: row; 
  gap: ${$datePicker ? '0px' : `${theme.spacing[5]}`};
  padding: ${theme.spacing[2]};

  ${breakpoints.mobile} {
    max-height: 557px;
    overflow-y: scroll;
  }
`,
);

export const MenuBlock = styled.div(
  ({ theme }) => `
  flex: 1;
  color: ${theme.colors.text};
`,
);

export const MobileContentView = styled.div`
  width: 100%;
  max-height: 557px;
`;

export const ScrollContent = styled.div`
  padding: 8px;
`;
