import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import TypeTerrainIcon from '@images/terraintype.svg';

import { ClientOnly } from '@/components/ClientProviders';
import { Checkbox } from '@/components/Filter/components/Checkbox';
import { FilterItemSelector } from '@/components/Filter/components/FilterItem';
import { useTerrainContext } from '@/context/useTerrainContext';

import { KindTerrainContainer, KindTypeItem, MenuBlock } from './styles';
import { Tooltip } from './Tooltip';

export interface TerrainItem {
  id: string | number;
  label: string;
  tooltipText?: string;
}

/**
 * @description Type terrain component
 */

export function TerrainTypeSelector() {
  const { types, setTypes, kinds, setKinds } = useTerrainContext();

  const { t } = useTranslation('filter');

  // type values to assign the checkboxes
  const typeTerraineItems = [
    { id: 'terrain', label: 'naturalCampingSites' },
    { id: 'group_terrain', label: 'groupNaturalCampingSites' },
  ];

  // kind values to assign the checkboxes
  const kindTerraineItems = [
    { id: 1, label: 'pureNature', tooltipText: t('tooltipTextFirstFilterItem') },
    { id: 0, label: 'naturePlus', tooltipText: t('tooltipTextSecondFilterItem') },
    { id: 2, label: 'natureComfort', tooltipText: t('tooltipTextThirdFilterItem') },
  ];

  // Handle change for kind type terrain selection
  const onChangeKinds = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setKinds([...kinds, Number(event.target.value)]);
    } else {
      setKinds(kinds.filter((prop) => prop != Number(event.target.value)));
    }
  };

  // Handle change for kind type terrain selection
  const onChangeTypes = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setTypes([...types, event.target.value]);
    } else {
      setTypes(types.filter((prop) => prop != event.target.value));
    }
  };

  // Render a type checkbox component with all harcoded value
  function renderTypeTerrainCheckbox(terraineSelectorItem: TerrainItem) {
    return (
      <Checkbox
        key={terraineSelectorItem.id}
        label={t(`${terraineSelectorItem.label}`)}
        value={terraineSelectorItem.id}
        onChange={onChangeTypes}
        checked={types.includes(String(terraineSelectorItem.id))}
      />
    );
  }

  // Render a kind checkbox component with all harcoded value
  function renderKindTerrainCheckbox(terraineSelectorItem: TerrainItem) {
    return (
      <>
        <Checkbox
          key={terraineSelectorItem.id}
          label={t(`${terraineSelectorItem.label}`)}
          value={terraineSelectorItem.id}
          onChange={onChangeKinds}
          checked={kinds.includes(Number(terraineSelectorItem.id))}
        />
        {terraineSelectorItem.tooltipText && <Tooltip text={terraineSelectorItem.tooltipText} />}
      </>
    );
  }

  return (
    <ClientOnly>
      <FilterItemSelector filterName={t('typeTerrainTitle')} icon={<TypeTerrainIcon />}>
        <MenuBlock>
          {/* Render first element from typeTerraineItems array acording to the design */}
          {renderTypeTerrainCheckbox(typeTerraineItems[0] as TerrainItem)}
          <KindTerrainContainer>
            {kindTerraineItems.map((kindTerraineSelectorItem) => (
              <KindTypeItem key={kindTerraineSelectorItem.id}>
                {renderKindTerrainCheckbox(kindTerraineSelectorItem)}
              </KindTypeItem>
            ))}
          </KindTerrainContainer>
        </MenuBlock>
        {/* Render second (last) element from typeTerraineItems array acording to the design */}
        <MenuBlock>{renderTypeTerrainCheckbox(typeTerraineItems[1] as TerrainItem)}</MenuBlock>
      </FilterItemSelector>
    </ClientOnly>
  );
}
