import { ChangeEvent } from 'react';

import { useTranslation } from 'next-i18next';

import LocationIcon from '@images/location-icon.svg';

import { ClientOnly } from '@/components/ClientProviders';
import { useTerrainContext } from '@/context/useTerrainContext';
import { useLocations } from '@/hooks/useLocations';
import { Location } from '@/lib/api-dtos';

import { Checkbox } from '../Checkbox/Checkbox';
import { FilterItemSelector } from '../FilterItem/FilterItem';
import { CountryLabel, MenuBlock, RightBottomColumnBlock, RightTopColumnBlock } from './styles';

/**
 * @description Location filter item
 */

export function LocationSelector() {
  const { t } = useTranslation('filter');
  const { locations, setLocations } = useTerrainContext();
  const { locations: allLocations } = useLocations();

  // Select locations
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setLocations([...locations, +event.target.value]);
    } else {
      setLocations(locations.filter((loc) => loc != +event.target.value));
    }
  };

  // Select country and all locations in it
  const selectAll = (countryWithLocations: Location) => () => {
    const countryLocationIds = countryWithLocations.children!.map(
      (location: { id: number }) => location.id,
    );
    const isSelected = countryLocationIds.every((id: number) => locations.includes(id));
    if (isSelected) {
      setLocations(locations.filter((loc) => !countryLocationIds.includes(loc)));
    } else {
      setLocations([...locations, ...countryLocationIds]);
    }
  };

  // Render a country component with all locations ans checkboxes
  function renderCountryWithAllLocations(countryId: number) {
    //Check if the county exist in the list of all coutries
    const country = allLocations?.find(
      (countyWithLocations) => countyWithLocations.id === countryId,
    );
    if (country) {
      return (
        <>
          <CountryLabel>
            <Checkbox
              label={country.name}
              onChange={selectAll(country)}
              checked={country?.children?.every((location) => locations.includes(location.id))}
            />
          </CountryLabel>
          {/* Render checkboxes for individual locations within the current country */}
          {country.children?.map((location) => (
            <div key={location.id}>
              <Checkbox
                value={location.id}
                label={location.name}
                onChange={onChange}
                checked={locations.includes(location.id)}
              />
            </div>
          ))}
        </>
      );
    } else {
      // empty fragment
      return <></>;
    }
  }

  return (
    <ClientOnly>
      <FilterItemSelector filterName={t('locationTitle')} icon={<LocationIcon />}>
        <MenuBlock>{renderCountryWithAllLocations(21)}</MenuBlock>
        <MenuBlock>
          <RightTopColumnBlock>{renderCountryWithAllLocations(13)}</RightTopColumnBlock>
          <RightBottomColumnBlock>{renderCountryWithAllLocations(32)}</RightBottomColumnBlock>
        </MenuBlock>
      </FilterItemSelector>
    </ClientOnly>
  );
}
