import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const InputLabel = styled.label(
  ({ theme }) => `
  display:flex;
  flex-direction:row;
  align-items: center;
  margin-bottom:calc(${theme.spacing[2]} - 4px);

  ${breakpoints.mobile} {
    align-items: baseline;
  }
`,
);

export const CheckBoxInput = styled.input.attrs({ type: 'checkbox' })(
  ({ theme }) => `
  margin-right: ${theme.spacing[1]};
  background: transparent;
  border-radius: 2px;
  border: 1px solid ${theme.colors.borderColor};
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  cursor: pointer;  

  &:checked{
    border: 1px solid ${theme.colors.primary};
  }

  &:checked::after {
    content: '✓';
    display: flex;
    justify-content: center;
    line-height: 1;
    font-size: 15px;
    color: rgb(106, 199, 136);
    opacity: 1;
    transition: opacity 0.25s ease 0s;
  }
  `,
);

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;

  ${breakpoints.mobile} {
    align-items: baseline;
  }
`;

export const FontIconContainer = styled.div(
  ({ theme }) => `
margin-right: ${theme.spacing[1]}
`,
);

export const LabelText = styled.span`
  cursor: pointer;
`;
