import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
`;

export const InfoTextContainer = styled.div<{
  $visible: boolean;
}>(
  ({ theme, $visible }) => `
  display: ${$visible ? 'block' : 'none'};
  position: fixed;
  border-radius: calc(${theme.spacing[1]} - 4px);
  border: 1px solid rgba(106, 199, 136, 0.40); 
  background : ${theme.colors.tooltipBackground};
  padding: ${theme.spacing[1]};
  transform: translate(-50%, -100%);
  max-width: 225px;
  width: 100%;
`,
);

export const InfoText = styled.span(
  ({ theme }) => `
  font-size:${theme.typography5.fontSize};
  font-weight: ${theme.typography4.fontWeight};
  line-height: ${theme.typography4.lineHeight};
  `,
);
