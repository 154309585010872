import React, { useState } from 'react';

import InfoIcon from '@images/InfoRounded.svg';

import { useIsTouchDevice } from '@/hooks/useIsTouchDevice';

import { ButtonContainer, InfoText, InfoTextContainer } from './styles';

/**
 * @description Information component that display text when you hover/ click on info icon
 */

export interface TooltipProps {
  text: string;
}

export function Tooltip({ text }: TooltipProps) {
  const isTouchDevice = useIsTouchDevice();
  const [showText, setShowText] = useState(false);

  // For touch devices
  const toggleShowText = () => {
    if (!isTouchDevice) return;
    const newOpenState = !showText;
    setShowText(newOpenState);
  };

  // For non touch devices
  const hoverShowText = (open: boolean) => {
    if (isTouchDevice) return;
    setShowText(open);
  };

  return (
    <div onMouseOver={() => hoverShowText(true)} onMouseOut={() => hoverShowText(false)}>
      <InfoTextContainer $visible={showText}>
        <InfoText>{text}</InfoText>
      </InfoTextContainer>
      <ButtonContainer onClick={() => toggleShowText()}>
        <InfoIcon />
      </ButtonContainer>
    </div>
  );
}
