import styled from 'styled-components';

export const Container = styled.div<{ $scrollable: boolean; $mobile: boolean }>(
  ({ theme, $scrollable, $mobile }) => `
  display:flex;
  flex-direction:row;
  justify-content: left;
  align-items: center;
  padding: ${$mobile ? `${theme.spacing[1]}` : `${theme.spacing[1]} ${theme.spacing[3]}`} ;
  gap: ${$mobile ? '8px' : '20px'};
  background-color: ${theme.colors.background};
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  height: ${theme.dimensions.filterHeight + 1}px;
  border-bottom: 1px solid rgba(18, 59, 43, 0.2);
  overflow-x:${$scrollable ? 'scroll' : 'hidden'};

  &::-webkit-scrollbar {
    display: none;
  }
`,
);
