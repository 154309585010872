import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const ControlPanel = styled.div(
  ({ theme }) => `
  display: block;
  position: fixed;
  z-index: 10;
  margin-top: 0.5rem;
`,
);

export const HideMap = styled.button(
  ({ theme }) => `
  display: none;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  border: 1px solid rgba(18, 59, 43, 0.2);
  background: rgba(255, 255, 255, 0.75);
  color: #123B2B;
  font-size:${theme.typography3.fontSize};
  font-weight:${theme.typography3.fontWeight};
  line-height:${theme.typography3.lineHeight};
  gap: 0.5rem;
  cursor: pointer;

  ${breakpoints.tablet} {
    display: flex;
  }
`,
);
