export interface CampingFeaturesItem {
  id: number;
  short_name: string;
  image?: string;
}

/**
 * @description Removing properties from the array that belogs to
 *  the Camping Equipment Filter selector and add two new values to the array
 */

export function filtercampingProperties(allProperties: CampingFeaturesItem[]) {
  if (allProperties) {
    // New values that should be added to the existing property array
    const newPropertiesToAdd = [
      { id: 106, short_name: 'Trouwen in de natuur' },
      { id: 109, short_name: 'Groepsaccomodatie aanwezig' },
    ];

    // Add new properties to the existing array
    const updatedProperties = allProperties.concat(newPropertiesToAdd);

    // List of property id's that belongs to Camping Equipment Filter selector and should beremoved
    const campingEquipmentPropertyIds = [3, 6, 4, 103, 5];

    return updatedProperties.filter(
      (property) => !campingEquipmentPropertyIds.includes(property.id),
    );
  } else {
    return [];
  }
}
