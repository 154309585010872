import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const MapContainer = styled.div<{ $visible: boolean; $visibleOnMobile: boolean }>(
  ({ theme, $visible, $visibleOnMobile }) => `
  display: flex;
  flex: ${$visible ? '1' : '0'} 0 0;
  transition: flex 0.25s ease-out;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;

  ${breakpoints.tablet} {
    display: ${$visible && $visibleOnMobile ? 'flex' : 'none'};
  }
`,
);
