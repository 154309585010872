import styled from 'styled-components';

export const DateContainer = styled.div(
  ({ theme }) => `
  display: inline-flex;
  padding-block: 8px;
  padding-inline:12px;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  border-radius: calc(${theme.spacing[1]} - 4px);
  border: 1px solid rgba(18, 59, 43, 0.20);
  background: #fff;
`,
);

export const DateInput = styled.input(
  ({ theme }) => `
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  border:none;
`,
);
