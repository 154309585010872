import Achterhoek from './geojson/Achterhoek.json';
import Groningen from './geojson/Groningen.json';
import LAW from './geojson/LAW.json';
import LF from './geojson/LF.json';
import Overijssel from './geojson/Overijssel.json';

/**
 * @description Harcoded routes values with geolocation json that are used in the Map component
 * / and colors for displaying on lines on the map
 */

export const listOfAvailableRoutes = [
  { id: 1, label: 'showLAWroutes', geodata: LAW, lineColor: '#253494' },
  { id: 2, label: 'routeAchterhoek', geodata: Achterhoek, lineColor: '#fd8d3c' },
  { id: 3, label: 'routeOverijssel', geodata: Overijssel, lineColor: '#bd0026' },
  { id: 4, label: 'showLFroutes', geodata: LF, lineColor: '#feb24c' },
  { id: 5, label: 'routeGroningen', geodata: Groningen, lineColor: '#f03b20' },
];
