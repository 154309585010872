import { useQuery } from '@tanstack/react-query';

import { Property } from '@/lib/api-dtos';
import { ApiError } from '@/lib/api-error';
import { PropertyService } from '@/services/PropertyService';

export const useProperties = () => {
  // error handling is not implemented yet
  const { data, isPending } = useQuery<Property[], ApiError>({
    queryKey: ['properties'],
    queryFn: () => new PropertyService().list(),
  });

  return { properties: data, isPending };
};
