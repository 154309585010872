export interface CampingEquipmentItem {
  id: number;
  label: string;
  short_name?: string;
}
// Return from  equipment property from the allPtoperties array
export function filterCampingEquipmentProperties(allProperties: CampingEquipmentItem[]) {
  if (allProperties) {
    const campingEquipmentPropertyIds = [3, 6, 4, 103, 5];
    return allProperties.filter((property) => campingEquipmentPropertyIds.includes(property.id));
  } else {
    return [];
  }
}

// Assign a label with hardcoded value to the property item for displaying it on the filter item
export function mapEquipmentProperties(allProperties: CampingEquipmentItem[]) {
  const campingProperties = filterCampingEquipmentProperties(allProperties);

  const campingEquipmentSelectorItems = [
    { id: 108, label: 'Hangmat' },
    { id: 3, label: 'Tent' },
    { id: 6, label: 'Vouwwagen' },
    { id: 4, label: 'Caravan' },
    { id: 103, label: 'Kampeerbusje' },
    { id: 5, label: 'Camper' },
    { id: 20, label: 'Duurzaam huisje' },
    { id: 110, label: 'Ingerichte tenten' },
  ];

  const mappedProperties =
    campingProperties?.map((property) => ({
      ...property,
      label:
        campingEquipmentSelectorItems.find((item) => item.id === property.id)?.label ||
        'Default Label',
    })) || [];

  return [
    ...mappedProperties,
    ...campingEquipmentSelectorItems.filter(
      (item) => !mappedProperties.some((property) => property.id === item.id),
    ),
  ];
}
