import {
  CampingEquipmentSelector,
  CampingFeatureSelector,
  DateSelector,
  LocationSelector,
  TerrainTypeSelector,
} from '@/components/Filter/components';
import { useTerrainContext } from '@/context/useTerrainContext';
import { useIsMobile } from '@/hooks/useIsMobile';

import { Container } from './styles';

export const Filter = () => {
  const isMobile = useIsMobile();
  const { lockFilterScroll } = useTerrainContext();

  return (
    <Container $mobile={isMobile} $scrollable={!lockFilterScroll}>
      <CampingEquipmentSelector />
      <CampingFeatureSelector />
      <LocationSelector />
      <DateSelector />
      <TerrainTypeSelector />
    </Container>
  );
};
