'use client';

import { Details } from '@/components/Details';
import { Filter } from '@/components/Filter';
import { Header } from '@/components/Header';
import { List } from '@/components/List';
import { Map } from '@/components/Map';

import { Container, Page } from './styles';

export const Home = () => {
  return (
    <Container>
      <Header />
      <Filter />
      <Page>
        <List />
        <Map />
        <Details />
      </Page>
    </Container>
  );
};
