import React, { useEffect, useRef, useState } from 'react';

import DownArrow from '@images/arrow-icon.svg';

import { useTerrainContext } from '@/context/useTerrainContext';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useIsTouchDevice } from '@/hooks/useIsTouchDevice';

import {
  ButtonContainer,
  ButtonLabel,
  ContainerWrapper,
  DropdownBox,
  DropdownContent,
  Input,
  MenuBlock,
  MobileContentView,
  ScrollContent,
} from './styles';

/**
 * @description Filter item component
 */

export interface FilterItemProps {
  filterName: string;
  icon: React.ReactNode;
  supportingContent?: React.ReactNode;
  children: React.ReactNode;
  lastFilterSelector?: boolean;
  datePickerFilterSelector?: boolean;
}

export function FilterItemSelector({
  filterName,
  icon,
  children,
  lastFilterSelector,
  datePickerFilterSelector,
}: FilterItemProps) {
  const isMobile = useIsMobile();
  const isTouchDevice = useIsTouchDevice();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { setLockFilterScroll } = useTerrainContext();

  // For touch devices
  const toggleDropDown = () => {
    if (!isTouchDevice) return;
    const newOpenState = !isOpen;
    setIsOpen(newOpenState);
    setLockFilterScroll(newOpenState);
  };

  // For non touch devices
  const setDropDown = (open: boolean) => {
    if (isTouchDevice) return;
    setIsOpen(open);
    setLockFilterScroll(open);
  };

  // Close the dropdown menu when a click occurs outside of the container
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Split children into two blocks (column)
  const splitChildrenIntoBlocks = (children: React.ReactNode) => {
    const childArray = React.Children.toArray(children);
    const halfCount = Math.ceil(childArray.length / 2);

    const firstBlock = childArray.slice(0, halfCount);
    const secondBlock = childArray.slice(halfCount);

    return [firstBlock, secondBlock];
  };

  return (
    <ContainerWrapper
      ref={containerRef}
      className="dropDownContainer"
      onMouseOver={() => setDropDown(true)}
      onMouseOut={() => setDropDown(false)}>
      <ButtonContainer $mobile={isMobile} $active={isOpen} onClick={() => toggleDropDown()}>
        {icon}
        <ButtonLabel $mobile={isMobile}>{filterName}</ButtonLabel>
        <DownArrow className={isOpen ? 'arrowIconUp' : 'arrowIconDown'} />
        <Input className="menuToggle" type="checkbox" id="menuToggle" name="menuToggle" />
      </ButtonContainer>
      <DropdownBox
        $visible={isOpen}
        $datePicker={datePickerFilterSelector as boolean}
        $lastElement={lastFilterSelector as boolean}>
        <DropdownContent $datePicker={datePickerFilterSelector as boolean}>
          {isMobile ? (
            <MobileContentView>
              <ScrollContent>{children}</ScrollContent>
            </MobileContentView>
          ) : (
            splitChildrenIntoBlocks(children).map((block, index) => (
              <MenuBlock key={index}>{block}</MenuBlock>
            ))
          )}
        </DropdownContent>
      </DropdownBox>
    </ContainerWrapper>
  );
}
