import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const MenuBlock = styled.div`
  flex: 1;
`;

export const RightTopColumnBlock = styled.div(
  ({ theme }) => `
  margin-bottom:${theme.spacing[5]};

  @media (width <= 768px) {
    margin-bottom:${theme.spacing[1]};
  }

  ${breakpoints.mobile} {
    margin-bottom:${theme.spacing[1]};
  }
`,
);

export const RightBottomColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
`;

export const CountryLabel = styled.div`
  font-weight: 700;
`;
