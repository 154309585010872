import styled from 'styled-components';

export const KindTerrainContainer = styled.div(
  ({ theme }) => `
  margin-left: ${theme.spacing[3]}
`,
);

export const KindTypeItem = styled.div(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing[1]};
`,
);

export const MenuBlock = styled.div`
  flex: 1;
`;
