import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LocationIcon from '@images/location-icon.svg';

import { useTerrainContext } from '@/context/useTerrainContext';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useIsVisible } from '@/hooks/useIsVisible';
import { useTerrains } from '@/hooks/useTerrains';
import { Terrain } from '@/lib/api-dtos';

import ListCard from './ListCard';
import {
  EntityCardContainer,
  HeaderRow,
  ListContainer,
  ListScrollContainer,
  ShowMap,
} from './styles';

export const List = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref);
  const isMobile = useIsMobile();

  const {
    terrain,
    showMapOnMobile,
    setShowMapOnMobile,
    setIsListOpen,
    search,
    locations,
    openAt,
    setTerrain,
    properties,
    types,
    kinds,
    lockFilterScroll,
    coordinates,
  } = useTerrainContext();
  const { terrains, isPending } = useTerrains(
    search,
    locations,
    openAt,
    properties,
    types,
    kinds,
    coordinates,
  );
  const [shuffledTerrains, setShuffledTerrains] = useState<Terrain[]>([]);
  const { t } = useTranslation('common');

  const hasFilter = () => {
    return (
      Boolean(search) ||
      locations.length > 0 ||
      Boolean(openAt) ||
      properties.length > 0 ||
      types.length > 0 ||
      kinds.length > 0 ||
      Boolean(coordinates)
    );
  };

  // Shuffle the terrains array
  useEffect(() => {
    if (terrains) {
      if (hasFilter()) {
        // do not shuffle the terrains when there is a filter
        setShuffledTerrains(terrains);
        return;
      }
      // Shuffle the fetched terrains array and store it in state
      const shuffled = terrains.sort(() => Math.random() - 0.5);
      setShuffledTerrains(shuffled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terrains, search, locations, openAt, properties, types, kinds]);

  useEffect(() => {
    if (terrain) {
      const section = document.querySelector(`#terrain-${terrain}`);
      section?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [terrain]);

  useEffect(() => {
    setIsListOpen(isVisible);
  }, [isVisible, setIsListOpen]);

  return (
    <ListContainer ref={ref} $visibleOnMobile={!showMapOnMobile && !terrain}>
      <ShowMap $scrollable={lockFilterScroll} onClick={() => setShowMapOnMobile(true)}>
        <LocationIcon />
        <div>{isMobile ? t('showMapMobile') : t('showMap')}</div>
      </ShowMap>
      <ListScrollContainer>
        {isPending && <HeaderRow>{t('loading')}</HeaderRow>}
        {!isPending && <HeaderRow>{`${shuffledTerrains.length} ${t('results')}`}</HeaderRow>}
        {shuffledTerrains.map((terr, index) => (
          <EntityCardContainer
            id={`terrain-${terr.id}`}
            key={`terrain-${terr.id}`}
            onClick={() => setTerrain(terr.id)}
            aria-description={`${terr.name}: ${terr.city}, ${terr.state.name}`}
            aria-pressed>
            <ListCard terrain={terr} />
          </EntityCardContainer>
        ))}
      </ListScrollContainer>
    </ListContainer>
  );
};
