import React, { ChangeEvent } from 'react';

import { useTranslation } from 'next-i18next';

import PropertiesIcon from '@images/property-icon.svg';

import { ClientOnly } from '@/components/ClientProviders';
import { Checkbox } from '@/components/Filter/components/Checkbox';
import { useTerrainContext } from '@/context/useTerrainContext';
import { useProperties } from '@/hooks/useProperties';

import { FilterItemSelector } from '../FilterItem/FilterItem';
import { CampingFeaturesItem, filtercampingProperties } from './campingFeatureUtils';
import { MenuBlock } from './styles';

/**
 * @description Camping Features Filter selector
 */

export function CampingFeatureSelector() {
  const { t } = useTranslation('filter');
  const { properties: allProperties } = useProperties();
  const { properties, setProperties } = useTerrainContext();

  // Getting feature properties to render thm on the page
  const featureProperties = filtercampingProperties(allProperties as CampingFeaturesItem[]);

  // Handle change in camping equipment (properties) selection
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setProperties([...properties, +event.target.value]);
    } else {
      setProperties(properties.filter((prop) => prop != +event.target.value));
    }
  };

  // Render a checkbox component
  function renderCheckbox(propertyItem: CampingFeaturesItem) {
    return (
      <Checkbox
        key={propertyItem.id}
        id={propertyItem.id}
        label={propertyItem.short_name}
        value={propertyItem.id}
        onChange={onChange}
        checked={properties.includes(propertyItem.id)}
      />
    );
  }

  return (
    <ClientOnly>
      <FilterItemSelector filterName={t('featureTitle')} icon={<PropertiesIcon />}>
        <MenuBlock>
          {featureProperties
            ?.filter((e, index) => index % 2 == 0)
            .map((propertyItem) => renderCheckbox(propertyItem))}
        </MenuBlock>
        <MenuBlock>
          {featureProperties
            ?.filter((_, index) => index % 2 === 1)
            .map((propertyItem) => renderCheckbox(propertyItem))}
        </MenuBlock>
      </FilterItemSelector>
    </ClientOnly>
  );
}
