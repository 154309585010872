import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  default as MapBox,
  GeolocateControl,
  Layer,
  MapRef,
  Marker,
  NavigationControl,
  Source,
} from 'react-map-gl';

import Image from 'next/image';

import PropertyIcon from '@images/property-icon.svg';
import * as turf from '@turf/turf';
import styled from 'styled-components';

import { listOfAvailableRoutes } from '@/components/Filter/components/RouteSelector/routeType';
import { useTerrainContext } from '@/context/useTerrainContext';
import { useTerrains } from '@/hooks/useTerrains';
import { Terrain } from '@/lib/api-dtos';

import ListCard from '../List/ListCard';
import { MapContainer } from './styles';
import { ControlPanel, HideMap } from './styles/ControlPanel';
import { PopupCard, StyledPopup } from './styles/Popup';

const TERRAIN_HOVER = '/assets/images/pinHoverGreen.svg';
const TERRAIN = '/assets/images/locationPinDefault.svg';
const GROUP_TERRAIN_HOVER = '/assets/images/pinLocationBlueHover.svg';
const GROUP_TERRAIN = '/assets/images/blueLocationPin.svg';

export const Map = () => {
  const {
    terrain,
    setTerrain,
    showMapOnMobile,
    setShowMapOnMobile,
    isListOpen,
    search,
    locations,
    coordinates,
    openAt,
    properties,
    routes,
    types,
    kinds,
  } = useTerrainContext();
  const { terrains } = useTerrains(
    search,
    locations,
    openAt,
    properties,
    types,
    kinds,
    coordinates,
  );
  const [selectedTerrain, setSelectedTerrain] = useState<Terrain | null>(null);
  const { t } = useTranslation('common');

  const mapRef = useRef<MapRef>(null);

  //Get the route data according to the selected filter item
  const selectedroutes = listOfAvailableRoutes.filter((route) => routes.includes(route.id));

  // draw circle
  const radius = 25;
  const center = [coordinates?.longitude ?? 0, coordinates?.latitude ?? 0];
  const circle = turf.circle(center, radius, { steps: 200, units: 'kilometers' });
  const line = turf.lineString(circle.geometry.coordinates[0] ?? []);

  const defaultCenter = { lat: 52.092876, lng: 5.10448 };
  const hasFilter = useCallback(() => {
    return (
      Boolean(search) ||
      locations.length > 0 ||
      Boolean(openAt) ||
      properties.length > 0 ||
      types.length > 0 ||
      kinds.length > 0 ||
      Boolean(coordinates)
    );
  }, [
    coordinates,
    kinds.length,
    locations.length,
    openAt,
    properties.length,
    search,
    types.length,
  ]);

  useEffect(() => {
    if (terrains && terrains.length > 0) {
      if (hasFilter()) {
        const minLat = Math.min(...terrains.map((t) => +t.latitude));
        const maxLat = Math.max(...terrains.map((t) => +t.latitude));
        const minLng = Math.min(...terrains.map((t) => +t.longitude));
        const maxLng = Math.max(...terrains.map((t) => +t.longitude));
        mapRef.current?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: 100, maxZoom: 10 });
      } else {
        mapRef.current?.flyTo({
          center: defaultCenter,
          zoom: 7,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFilter, terrains]);

  useEffect(() => {
    mapRef.current?.resize();
    setTimeout(() => {
      mapRef.current?.resize();
    }, 100);
    setTimeout(() => {
      mapRef.current?.resize();
    }, 200);
    setTimeout(() => {
      mapRef.current?.resize();
    }, 300);
  }, [showMapOnMobile, terrain]);

  const onClick = (terrain: Terrain) => {
    if (isListOpen) {
      setTerrain(terrain.id);
    } else {
      setSelectedTerrain(terrain);
    }
  };

  const onMouseOver = (terrain: Terrain) => {
    if (isListOpen) {
      setSelectedTerrain(terrain);
    }
  };

  const onMouseOut = () => {
    if (isListOpen) {
      setSelectedTerrain(null);
    }
  };

  return (
    <MapContainer $visible={!terrain} $visibleOnMobile={showMapOnMobile}>
      <MapBox
        ref={mapRef}
        mapLib={import('mapbox-gl')}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_KEY}
        initialViewState={{
          latitude: coordinates?.latitude ?? defaultCenter.lat,
          longitude: coordinates?.longitude ?? defaultCenter.lng,
          zoom: 7,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/streets-v9">
        <NavigationControl />
        <GeolocateControl />
        {/* show on marker for search location */}
        {coordinates && (
          <>
            <Source type="geojson" data={circle}>
              <Layer
                id="point-90-hi"
                type="fill"
                paint={{
                  'fill-color': '#6AC788',
                  'fill-opacity': 0.25,
                }}
              />
            </Source>

            <Source type="geojson" data={line}>
              <Layer
                id="point-9-hi"
                type="line"
                paint={{
                  'line-color': '#123B2B',
                  'line-width': 2,
                }}
              />
            </Source>
          </>
        )}
        {coordinates && (
          <Marker
            latitude={Number(coordinates.latitude)}
            longitude={Number(coordinates.longitude)}
          />
        )}
        {/* all (filtered) locations */}
        {terrains?.map((terr) => (
          <Marker
            key={`map-${terr.id}`}
            longitude={Number(terr.longitude)}
            latitude={Number(terr.latitude)}
            anchor="bottom">
            <MarkerContent
              onClick={() => onClick(terr)}
              onMouseOver={() => onMouseOver(terr)}
              onMouseOut={() => onMouseOut()}>
              <Image {...pinImageProps(terr, selectedTerrain)} alt={terr.name} />
            </MarkerContent>
          </Marker>
        ))}
        {selectedTerrain && (
          <StyledPopup
            anchor="top"
            closeButton={!isListOpen}
            closeOnClick={false}
            closeOnMove={false}
            focusAfterOpen={false}
            longitude={Number(selectedTerrain.longitude)}
            latitude={Number(selectedTerrain.latitude)}
            onClose={() => setSelectedTerrain(null)}
            style={{
              boxShadow: '1px 1px 1px 0px #0000000A',
            }}>
            <PopupCard onClick={() => setTerrain(selectedTerrain.id)}>
              <ListCard terrain={selectedTerrain} imageType="small" />
            </PopupCard>
          </StyledPopup>
        )}
        {/* show the selected routes */}
        {selectedroutes.map((route) => (
          <Source
            key={route.id}
            id={`my-geojson-source-${route.id}`}
            type="geojson"
            data={route.geodata as unknown as GeoJSON.Geometry}>
            <Layer
              key={`my-geojson-layer-${route.id}`}
              id={`my-geojson-layer-${route.id}`}
              type="line"
              paint={{
                'line-color': route.lineColor,
                'line-width': 4,
              }}
            />
          </Source>
        ))}
      </MapBox>
      <ControlPanel>
        <HideMap onClick={() => setShowMapOnMobile(false)}>
          <PropertyIcon />
          <div>{t('showList')}</div>
        </HideMap>
      </ControlPanel>
    </MapContainer>
  );
};

const MarkerContent = styled.div(
  ({ theme }) => `
  cursor: pointer;
`,
);

const pinImageProps = (terrain: Terrain, selectedTerrain: Terrain | null) => {
  const isGroupTerrain = terrain.type === 'group_terrain';
  const isSelected = terrain.id === selectedTerrain?.id;

  const size = isSelected ? 68 : 40;

  return {
    src: pinImageSrc(isSelected, isGroupTerrain),
    width: size,
    height: size,
  };
};

const pinImageSrc = (isSelected: boolean, isGroupTerrain: boolean) => {
  if (isSelected) {
    return isGroupTerrain ? GROUP_TERRAIN_HOVER : TERRAIN_HOVER;
  }

  return isGroupTerrain ? GROUP_TERRAIN : TERRAIN;
};
