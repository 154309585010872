import { Property } from '@/lib/api-dtos';
import fetcher from '@/utils/fetcher';

export class PropertyService {
  async list(): Promise<Property[]> {
    return fetcher<Property[]>({
      url: '/api/proxy/properties',
    });
  }
}
