import React, { ChangeEvent } from 'react';

import { FontIcon } from '@/components/FontIconHelper';

import { CheckBoxInput, ContentWrapper, FontIconContainer, InputLabel, LabelText } from './styles';

export interface CheckboxProps {
  value?: number | string;
  id?: number;
  label: string;
  checked?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * @description Chekbox component
 */

export function Checkbox({ value, id, label, checked, onChange }: CheckboxProps) {
  return (
    <>
      <InputLabel>
        <CheckBoxInput type="checkbox" value={value} checked={checked} onChange={onChange} />
        <ContentWrapper>
          {id && (
            <FontIconContainer>
              <FontIcon id={id} />
            </FontIconContainer>
          )}
          <LabelText>{label}</LabelText>
        </ContentWrapper>
      </InputLabel>
    </>
  );
}
