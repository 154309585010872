import { Popup as MapboxPopup } from 'react-map-gl';

import styled from 'styled-components';

export const StyledPopup = styled(MapboxPopup)`
  .mapboxgl-popup-content {
    padding: ${(props) => props.theme.spacing[1]};
  }

  .mapboxgl-popup-close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.whiteText};
    border-radius: 4px;
    top: ${(props) => props.theme.spacing[1]};
    right: ${(props) => props.theme.spacing[1]};
    width: ${(props) => props.theme.spacing[4]};
    height: ${(props) => props.theme.spacing[4]};
    margin-right: 4px;
    margin-top: 4px;
    color: ${(props) => props.theme.colors.popUpCloseButton};
    font-size: 2rem;
  }
`;

export const PopupCard = styled.div`
  max-width: 15rem !important;
`;
