import styled, { keyframes } from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const ListContainer = styled.div<{ $visibleOnMobile: boolean }>(
  ({ $visibleOnMobile }) => `
  display: flex;
  justify-content: center;
  width: 36rem;
  border-right: 1px solid rgba(18, 59, 43, 0.2);

  ${breakpoints.responsiveTablet} {
    display: ${$visibleOnMobile ? 'flex' : 'none'};
    border-right: none;
    width: calc(36rem/2);
  }

  ${breakpoints.mobile} {
    display: ${$visibleOnMobile ? 'flex' : 'none'};
    border-right: none;
  }
`,
);

export const ListScrollContainer = styled.div`
  --gap: 1.5rem;
  container-type: inline-size;
  grid-template-columns: 1fr 1fr;
  display: grid;
  padding: var(--gap);
  grid-gap: var(--gap);
  grid-auto-rows: max-content;
  overflow-y: scroll;
  width: 100%;

  ${breakpoints.responsiveTablet} {
    display: flex;
    flex-direction: column;
  }

  ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const fadeOut = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const EntityCardContainer = styled.button`
  border: 0;
  background: none;
  text-align: left;
  container-type: inline-size;
  animation: ${fadeOut} 1s;
  animation-range: entry -10%;
  animation-timeline: view();
  cursor: pointer;
`;

export const Shadow = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0,
    rgba(255, 255, 255, 0) 5px,
    rgba(255, 255, 255, 0) calc(100% - 5px),
    rgba(255, 255, 255, 1) 100%
  );
`;

export const ShowMap = styled.button<{ $scrollable: boolean }>(
  ({ theme, $scrollable }) => `
  display: none;
  position: fixed;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  border: 1px solid rgba(18, 59, 43, 0.2);
  background: rgba(255, 255, 255, 0.75);
  color: #123B2B;
  font-size:${theme.typography3.fontSize};
  font-weight:${theme.typography3.fontWeight};
  line-height:${theme.typography3.lineHeight};
  gap: 0.5rem;
  cursor: pointer;
  z-index: 999;

  ${breakpoints.tablet} {
    display: ${$scrollable ? 'none' : 'flex'} ;
  }
`,
);

export const HeaderRow = styled.div`
  grid-column: span 2;
`;
