import React, { ChangeEvent } from 'react';

import { useTranslation } from 'next-i18next';

import CampingEquipmentIcon from '@images/campingtype.svg';

import { ClientOnly } from '@/components/ClientProviders';
import { Checkbox } from '@/components/Filter/components/Checkbox';
import { useTerrainContext } from '@/context/useTerrainContext';
import { useProperties } from '@/hooks/useProperties';

import { FilterItemSelector } from '../FilterItem/FilterItem';
import { CampingEquipmentItem, mapEquipmentProperties } from './campingEquipmentUtils';
import { MenuBlock } from './styles';

/**
 * @description Camping Equipment Filter selector
 */

export function CampingEquipmentSelector() {
  const { t } = useTranslation('filter');
  const { properties: allProperties } = useProperties();
  const { properties, setProperties } = useTerrainContext();

  // Getting equipment properties with Hangmat in it
  const equipmentProperties = mapEquipmentProperties(allProperties as CampingEquipmentItem[]);

  // Handle change in camping equipment selection
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setProperties([...properties, +event.target.value]);
    } else {
      setProperties(properties.filter((prop) => prop != +event.target.value));
    }
  };

  // Render a checkbox component with all harcoded value
  function renderCheckbox(campingEquipmentItem: CampingEquipmentItem) {
    return (
      <Checkbox
        key={campingEquipmentItem.id}
        label={t(`${campingEquipmentItem.label}`)}
        value={campingEquipmentItem.id}
        onChange={onChange}
        checked={properties.includes(campingEquipmentItem.id)}
      />
    );
  }

  return (
    <ClientOnly>
      <FilterItemSelector filterName={t('campingEquipmentTitle')} icon={<CampingEquipmentIcon />}>
        <MenuBlock>
          {equipmentProperties
            .filter((e, index) => index % 2 == 0)
            .map((campingEquipmentItem) => renderCheckbox(campingEquipmentItem))}
        </MenuBlock>
        <MenuBlock>
          {equipmentProperties
            .filter((e, index) => index % 2 == 1)
            .map((campingEquipmentItem) => renderCheckbox(campingEquipmentItem))}
        </MenuBlock>
      </FilterItemSelector>
    </ClientOnly>
  );
}
