import React from 'react';
import { useTranslation } from 'react-i18next';

import { Terrain } from '@/lib/api-dtos';
import { getImageUrl } from '@/utils/getImageUrl';
import { roundAverageRating } from '@/utils/roundAverageRating';

import { IconsSection } from '../Details/components/HeaderIconSection';
import {
  CardContainer,
  EntityImage,
  EntityImageHolder,
  EntityImageProps,
  GroupLabel,
  IconsContainer,
  MoreInfoBlock,
  Name,
  PlaceState,
  RatingContainer,
  RatingStar,
} from './styles/ListCard';

export interface ListcardProps {
  terrain: Terrain;
  imageType?: EntityImageProps['type'];
}
/**
 * @description SearchBar component responsable to take the user keywords, and send the
 *  searchTerm to the searchBarQuery to be filtered and show the results
 * @author Amanda Carneiro
 */
export default function ListCard({ terrain, imageType = 'normal' }: ListcardProps) {
  const { name, city, state, preview_photo, type } = terrain;
  const { t } = useTranslation('common');
  const imageUrl = getImageUrl(preview_photo.large_url || preview_photo.thumb_url);

  // This function will filter and limit the number of icons to 6
  const getLimitedIcons = (properties: Terrain['properties']) => {
    const filteredProperties = terrain.properties
      .map((property) => ({ extra: '', property }))
      .slice(0, 6); // Limit to 6 icons

    return filteredProperties;
  };

  const limitedIcons = getLimitedIcons(terrain.properties);

  return (
    <CardContainer>
      <EntityImageHolder>
        <EntityImage type={imageType} src={imageUrl} alt={name} width="240" height="200" />
        {type === 'group_terrain' && <GroupLabel>{t('groupTerrain')}</GroupLabel>}
      </EntityImageHolder>
      <Name>{name}</Name>
      <MoreInfoBlock>
        <PlaceState>
          {city}, {state.name}
        </PlaceState>
      </MoreInfoBlock>
      <IconsContainer>
        <IconsSection property={limitedIcons} />
        <RatingContainer>
          {terrain.reviews.length >= 1 ? (
            <>
              <RatingStar
                src={'/assets/images/ratingStar.svg'}
                alt="rating green star"
                width="13"
                height="13"
              />
              <span>{roundAverageRating(terrain.reviews)}</span>
            </>
          ) : (
            <></>
          )}
        </RatingContainer>
      </IconsContainer>
    </CardContainer>
  );
}
