import React, { ChangeEvent } from 'react';

import { useTranslation } from 'next-i18next';

import RoutesIcon from '@images/walking.svg';

import { ClientOnly } from '@/components/ClientProviders';
import { useTerrainContext } from '@/context/useTerrainContext';

import { MenuBlock } from '../CampingEquipmentFilterSelector/styles';
import { Checkbox } from '../Checkbox';
import { FilterItemSelector } from '../FilterItem/FilterItem';
import { listOfAvailableRoutes } from './routeType';

export interface RouteSelectorItem {
  id: number;
  label: string;
}

/**
 * @description Walking and Cycling Route Filter Selector
 */

export function RouteSelector() {
  const { t } = useTranslation('filter');
  const { routes, setRoutes } = useTerrainContext();

  // Select routes from the list
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setRoutes([...routes, +event.target.value]);
    } else {
      setRoutes(routes.filter((prop) => prop != +event.target.value));
    }
  };

  // Render a checkbox component with all data in it
  function renderCheckbox(route: RouteSelectorItem) {
    return (
      <Checkbox
        key={route.id}
        label={t(`${route.label}`)}
        value={route.id}
        onChange={onChange}
        checked={routes.includes(route.id)}
      />
    );
  }

  return (
    <ClientOnly>
      <FilterItemSelector
        lastFilterSelector={true}
        filterName={t('routesTitle')}
        icon={<RoutesIcon />}>
        <MenuBlock>
          {listOfAvailableRoutes.slice(0, 3).map((route) => renderCheckbox(route))}
        </MenuBlock>
        <MenuBlock>
          {listOfAvailableRoutes.slice(3, 5).map((route) => renderCheckbox(route))}
        </MenuBlock>
      </FilterItemSelector>
    </ClientOnly>
  );
}
