import React from 'react';
import { useTranslation } from 'react-i18next';

import CalendarIcon from '@images/calendar.svg';

import { ClientOnly } from '@/components/ClientProviders';
import { FilterItemSelector } from '@/components/Filter/components/FilterItem';
import { useTerrainContext } from '@/context/useTerrainContext';

import { DateContainer, DateInput } from './styles';

/**
 * @description Date Picker component
 */

export function DateSelector() {
  const { t } = useTranslation('filter');
  const currentDay = new Date().toISOString().slice(0, 10);
  const { openAt, setOpenAt } = useTerrainContext();

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenAt(event.target.value);
  };

  return (
    <ClientOnly>
      <FilterItemSelector
        datePickerFilterSelector={true}
        filterName={t('dateTitle')}
        icon={<CalendarIcon />}>
        <DateContainer>
          <DateInput
            type="date"
            id="start"
            name="trip-start"
            value={openAt}
            min={currentDay}
            onChange={handleDateChange}
          />
        </DateContainer>
      </FilterItemSelector>
    </ClientOnly>
  );
}
